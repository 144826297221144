<template>
    <div class="zt-page-content" ref="load">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck2" label-width="120px">
            <div class="zt-block">
                <div class="zt-block-head">销售信息</div>
                <div class="zt-block-content">
                    <el-form-item label="销售价格" prop="price">
                        <el-input style="width:120px;" type="number" v-model.number="ruleForm.price"></el-input>
                        <span class="font14-grey1" style="color:#F66F6A;margin-left:10px"
                            >元（RMB）</span>
                    </el-form-item>
                    <el-form-item v-if="!orderId" label="销售渠道" prop="price_type">
                        <el-radio-group v-model="ruleForm.price_type">
                            <el-radio :label="0">设计版权销售</el-radio>
                            <!-- <el-radio v-if="!type" :label="1">分成合作销售</el-radio> -->
                            <!-- <el-radio :label="3">需求定制订单</el-radio> -->
                            <el-radio :label="4">暂不上架</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="ruleForm.price_type==0" label="合同信息" prop="contract_file">
                        <div>
                            <el-radio-group v-model="ruleForm.custom_cover_page">
                                <el-radio :label="0">自动填充页头页尾</el-radio>
                                <el-radio :label="1">自定义页头页尾</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="contrant-upload" style="max-width:600px">
                            <UploadPdf v-if="showImgs" v-model="ruleForm.contract_file" 
                                prefix='ht/banquan' 
                                :type="ruleForm.custom_cover_page" 
                                :contract_type="1">
                            </UploadPdf>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="ruleForm.price_type==1" label="分成比例：" prop="price_percent">
                        <div class="input-red flex">
                            <div>按销售额每单</div>
                            <el-input style="width:120px;margin:0 12px;" type="number" v-model.number="ruleForm.price_percent"></el-input>
                            <div style="color:#F66F6A;">%</div>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item v-if="ruleForm.price_type==3" label="选择进行中的生意订单" prop="order_id">
                        <SelectOrder v-model="ruleForm.order_id"></SelectOrder>
                    </el-form-item> -->
                    
                </div>
            </div>
        </el-form>

    </div>
</template>

<script>
    import { clone } from 'lodash';
    import { singleDetail, addOrUpdateProduct } from '@/service/design';
    import UploadPdf from '@/components/upload/UploadPdf.vue';
    // import SelectOrder from './SelectOrder.vue';
    export default {
        components:{
            UploadPdf,
            // SelectOrder
        },
        props:['form','type'],
        data() {
            var checkPercent = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请填写分成比例'));
                } else {
                    if (value>100) {
                        return callback(new Error('不能大于100%'));
                    } else if (value<=0) {
                        return callback(new Error('不能小于等于0%'));
                    } else {
                        callback();
                    }
                }
            };
            var checkPrice = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请填写价格'));
                } else {
                    if (value<=0) {
                        return callback(new Error('价格不能小于等于0'));
                    } else {
                        callback();
                    }
                }
            };
            return {
                ruleForm: {
                    id: "",
                    price:"",
                    price_type: 4,
                    contract_file: "",
                    custom_cover_page: 0, // 0自动填充页头页尾, 1自定义页头页尾
                    price_percent: "", // 设计公司分成百分比x100，范围0--10000
                    // order_id: "", // 订单id，用于需求定制订单
                    // order_intro: "", // 订单备注，用于需求定制订单
                },
                rules: {
                    price: [{ required: true, validator: checkPrice, trigger: 'blur' }],
                    price_type: [{ required: true, message: '请选择销售渠道', trigger: 'blur' }],
                    contract_file: [{ required: true, message: '请上传合同信息', trigger: 'blur' }],
                    price_percent: [{validator: checkPercent, trigger: 'blur'}],
                    order_id: [{ required: true, message: '选择进行中的生意订单', trigger: 'blur' }],
                },
                showImgs: true,
                orderId: "",
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                    if (this.$route.query.orderId) {
                        this.orderId = this.$route.query.orderId;
                        this.ruleForm.price_type = 4;
                    }
                },
                deep: true
            }
        },
        created() {
            if (this.form && this.form.id) {
                this.ruleForm = this.form;
                this.showImgs = true;
            } else {
                this.showImgs = true;
            }
            if (this.$route.query.orderId) {
                this.orderId = this.$route.query.orderId;
                this.ruleForm.price_type = 4;
            }
            if (this.ruleForm.price==0) {
                this.ruleForm.price = "";
            }
            console.log("ruleForm",this.ruleForm,this.form);
        },
        mounted() {},
        methods: {
            refreshItem() {
                singleDetail(this.ruleForm.id).then(rst => {
                    console.log("rst",rst);
                    if (rst) {
                        this.ruleForm = {
                            price_type: rst.price_type,
                            price: rst.price,
                            contract_file: rst.contract_file,
                            custom_cover_page: rst.custom_cover_page,
                            // order_id: rst.order_id,
                            // order_intro: rst.order_intro
                        };
                        if (rst.price_percent) {
                            this.ruleForm.price_percent = Math.floor(rst.price_percent * 100)/10000;
                        }
                    }
                    console.log("ruleForm",this.ruleForm);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            // 保存
            submitForm2() {
                let _item = clone(this.ruleForm);
                if (this.orderId) {
                    _item.price_type = 4;
                }
                this.$refs['formCheck2'].validate((valid) => {
                    if (valid) {
                        this.$emit("step",_item,3);
                    }
                });
            },
        }
    }
</script>
<style>
    .input-red>>> .el-input__inner {
        padding: 0 0 0 15px;
    }
</style>
<style scoped lang="less">
    .el-input, .el-cascader, .el-select {
        width: 240px;
    }
</style>