const LOCALSTROAGE_PREFIX = 'cyzlw_';
export default class BaseStorage {
  // 是否是 sessionStorage (默认为 localStorage)
  static isSession = false;
  // 是否加 localstorage prefix 前缀
  static noPrefix = false;

  static getStorage() {
    if (!window.localStorage) {
      throw new Error("该浏览器不支持本地存储")
    }
    return this.isSession ? window.sessionStorage : window.localStorage
  }

  /** 获取 key */
  static getKey(key) {
    if (this.noPrefix) { return key; }
    return LOCALSTROAGE_PREFIX + key;
  }
  
  /** 设置 key */
  static setItem(key, val) {
    this.getStorage().setItem(this.get_key(key), String(val));
  }

  static getItem(key) {
    return this.getStorage().getItem(this.get_key(key)) || '';
  }

  /** 删除 */
  remove(...keys) {
    if (!keys || !keys.length) { return false; }

    (keys).forEach((key) => this.getStorage().removeItem(this.get_key(key)));
    return true;
  }
}